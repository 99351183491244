
import { errMessage, restartLogin, authenticateAccount, userSignedIn, userData, sendUserPasswordResetEmail, emailSent } from '@/firebase'
import { useRoute, useRouter } from 'vue-router'
import { reactive, watch, computed, onMounted, ref } from 'vue'
import { useStore } from 'vuex'

export default {
  setup (): any {
    const store = useStore()
    const route = useRoute()
    const router = useRouter()
    const form = reactive({ email: '', password: '' })
    const slug = route.params.slug.toString()
    const baseStatus = computed(() => store.getters.getBaseStatus)
    const userAuthenticated = computed(() => store.getters.getUserAuthenticated)
    const loginActive = ref(false)
    const passwordReset = ref(false)
    const initPasswordReset = () => {
      passwordReset.value = true
    }
    const submitPasswordReset = () => {
      sendUserPasswordResetEmail(form.email)
      // passwordReset.value = false
    }
    const endPasswordReset = () => {
      passwordReset.value = false
    }
    const clearProcess = async () => {
      await restartLogin()
      loginActive.value = false
    }
    const submitForm = async () => {
      loginActive.value = true
      await authenticateAccount({ ...form }, slug)
    }
    const finalizeSignin = () => {
      router.push('/' + slug)
      form.email = ''
      form.password = ''
    }
    const initSignIn = () => {
      // console.log('Retreiving User Type')
      store.dispatch('setUserLoggedIn', userData.value)
      store.dispatch('findUserRecordId', userData.value.email)
    }
    onMounted(() => {
      // console.log('Signed In state:', userSignedIn)
      if (userSignedIn.value) {
        initSignIn()
      }
    })
    watch(userSignedIn, (newValue) => {
      if (newValue) {
        initSignIn()
      }
    })
    watch(baseStatus, (newValue) => {
      if (newValue === '404') {
        router.push('/not-found')
      }
    })
    watch(userAuthenticated, (newValue) => {
      if (newValue) {
        // console.log('user authenticated')
        finalizeSignin()
      } else {
        // console.log('user not authenticated')
      }
    })
    return {
      errMessage,
      form,
      submitForm,
      clearProcess,
      userSignedIn,
      userAuthenticated,
      loginActive,
      passwordReset,
      initPasswordReset,
      endPasswordReset,
      submitPasswordReset,
      emailSent,
      baseName: computed(() => store.getters.getBaseName)
    }
  }
}
